.App {
  background-color: #fff4f8;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;
  overflow: auto;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
  color: #0063b1;
}

.suh-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.suh {
  width: 15px;
  height: 15px;
}

p {
  margin-bottom: 2px;
  margin-top: 2px;
}

i {
  font-size: small;
}

.event {
  /* font-style: italic; */
  margin: 20px 0;
  width: 90%; /* Default to full width */

  .title {
    font-size: large;
    font-style: normal;
  }
  
  .subtitle {
    font-size: large;
    font-style: normal;
  }

  p {
    font-style: italic;
    font-size: small;
  }

  a {
    text-decoration: underline;
  }
}

@media (min-width: 768px) { /* Adjust this breakpoint as needed */
  .event {
    width: 500px;
    margin: 20px 0;
  }

  .thank-you {
    width: 500px;
    /* margin: 20px 0; */
  
  }
}

.rsvp {
  font-style: italic;
  font-size: small;

  div {
    margin-top: 10px;
    /* max-width: 50%; */
  }

  .title {
    font-size: large;
    font-style: normal;
  }

  .name {
    display: flex;
    gap: 20px;
  }

  input {
    margin-top: 0 !important;
  }

  .input {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    margin-top: 5px;
  }
}
